


































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { Debounce } from 'lodash-decorators'
import { vxm } from '@/store'
import { formatDateTimeStringAsDate } from '@/utils/dateUtils'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component({})
export default class NewTyreHotel extends Vue {
  $refs: Vue['$refs'] & {
    newTyreHotelForm: {
      validate: any
      reset: any
    }
  }

  private appendSitePrefix = appendSitePrefix

  private loading = true

  private licensePlateInput = ''
  private filteredCars = []

  private carTyreHotels = []

  private tyreHotelProducts = []

  private locations = []

  private inputData = {
    carId: null,
    productId: null,
    wheelChangeLocationId: null,
    seasonInHotel: null,
  }

  private rules = {
    carId: null,
    productId: null,
    wheelChangeLocationId: null,
    seasonInHotel: null,
  }

  private saveLoading = false

  private async created() {
    this.rules = {
      carId: [(v) => !!v || this.$t('c:validation:This field is required')],
      productId: [(v) => !!v || this.$t('c:validation:This field is required')],
      wheelChangeLocationId: [(v) => !!v || this.$t('c:validation:This field is required')],
      seasonInHotel: [(v) => !!v || this.$t('c:validation:This field is required')],
    }

    if (this.$route.query.carId) {
      const carId = parseInt(this.$route.query.carId.toString())

      this.$axios
        .get('/v4/site/cars/' + carId)
        .then((response) => {
          const car = response.data.data

          this.filteredCars = [
            {
              id: car.id,
              licenseplate: car.licenseplate,
            },
          ]
          this.inputData.carId = car.id
        })
        .catch((error) => {
          vxm.alert.onAxiosError(error, 'Error reading the car')
        })
    }

    try {
      const responses = await Promise.all([
        this.$axios.get('/v4/site/products/tyre-hotel?perPage=100'),
        this.$axios.get('/v4/site/locations'),
        this.$axios.get('/v4/site/tyre-hotels-v2/current-season?invert=1'),
        this.$axios.get('/v4/site/locations/wheel-change-default'),
      ])

      this.tyreHotelProducts = responses[0].data.data
      this.locations = responses[1].data.data
      this.inputData.seasonInHotel = responses[2].data.season
      this.inputData.wheelChangeLocationId = responses[3].data.data.locationId
      this.loading = false
    } catch (err) {
      await vxm.alert.onAxiosError(err, 'Error in one of the requests')
    }
  }

  private create() {
    if (this.$refs.newTyreHotelForm.validate()) {
      this.saveLoading = true
      this.$axios
        .post('/v4/site/tyre-hotels-v2', this.inputData)
        .then((response) => {
          this.$router.push({
            name: 'TyreHotel/View',
            params: {
              tyreHotelId: response.data.data.id,
            },
          })
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error creating a new tyre hotel')
        })
        .finally(() => {
          this.saveLoading = false
        })
    }
  }

  @Watch('licensePlateInput')
  @Debounce(300)
  private onLicensePlateInputChange() {
    if (this.licensePlateInput && this.licensePlateInput.length >= 3) {
      this.$axios
        .get(`/v4/site/cars/search?licensePlate=${this.licensePlateInput}`)
        .then((response) => {
          this.filteredCars = response.data.data
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error reading cars')
        })
    }
  }

  @Watch('inputData.carId')
  private onCarSelected(): void {
    if (this.inputData.carId !== null) {
      this.$axios
        .get('/v4/site/tyre-hotels-v2/get-by-car/' + this.inputData.carId)
        .then((response) => {
          this.carTyreHotels = response.data.data
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Failed to load tyre hotel of car')
        })
    }
  }

  private formatTime(value): string {
    return formatDateTimeStringAsDate(value)
  }

  private newCarLink() {
    let url = '/N0/car/new'
    const params = []

    if (this.licensePlateInput) {
      params.push(`reg_no=${encodeURIComponent(this.licensePlateInput)}`)
    }

    // Add the fixed parameter
    params.push('back_to_new_hotel=1')

    // If there are any parameters, join them with '&' and append to the URL
    if (params.length > 0) {
      url += '?' + params.join('&')
    }

    url = appendSitePrefix(url)

    this.$router.push(url)
  }
}
